<template>
  <!-- 资讯详情页 -->
  <div class="bg">
    <div class="container">
      <div class="banner">
        <span>
          当前位置：
          <router-link to="/">众惠首页</router-link> 
          &gt;
          <router-link :to="{name:'Information'}">平台公告</router-link>
        </span>
      </div>
      <div class="middle">
        <div class="content-img">
          <img :src="newsInfo.webCoverUrl" alt />
        </div>
        <div class="content-text">
          <p>{{ newsInfo.noticeTitle }}</p>
          <div class="title">
            <span>发布时间：</span>
            <span
              style="margin-left:28px;font-size: 18px;font-weight: 300;color: #718096;"
            >{{ newsInfo.custDisplayTime }}</span>
            <div class="text" v-html="newsInfo.noticeContent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {newsDetail} from '@/api/news.js'
export default {
  name: 'NewsDetail',
  props: {
    id: {
      type: String,
      require: true 
    }
  },
  data() {
    return {
      newsInfo:{}
    }
  },
  created() {
    this.newsDetail()
  },
  methods: {    
    /** 公告详情 */
    newsDetail() {
      newsDetail(this.id).then(result => {
        this.newsInfo = result.data || {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled /deep/ ul[data-checked] > li::before {
  pointer-events: none;
}
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  /* padding: 12px 15px; */
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-editor > * {
  cursor: text;
}
/deep/ p,
/deep/ ol,
/deep/ ul,
/deep/ pre,
/deep/ blockquote,
/deep/ h1,
/deep/ h2,
/deep/ h3,
/deep/ h4,
/deep/ h5,
/deep/ h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
/deep/ ol,
/deep/ ul {
  padding-left: 1.5em;
}
/deep/ ol > li,
/deep/ ul > li {
  list-style-type: none;
}
/deep/ ul > li::before {
  content: '\2022';
}
/deep/ ul[data-checked=true],
/deep/ ul[data-checked=false] {
  pointer-events: none;
}
/deep/ ul[data-checked=true] > li *,
/deep/ ul[data-checked=false] > li * {
  pointer-events: all;
}
/deep/ ul[data-checked=true] > li::before,
/deep/ ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
/deep/ ul[data-checked=true] > li::before {
  content: '\2611';
}
/deep/ ul[data-checked=false] > li::before {
  content: '\2610';
}
/deep/ li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
/deep/ li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
/deep/ li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
/deep/ ol li:not(.ql-direction-rtl),
/deep/ ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
/deep/ ol li.ql-direction-rtl,
/deep/ ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
/deep/ ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
/deep/ ol li:before {
  content: counter(list-0, decimal) '. ';
}
/deep/ ol li.ql-indent-1 {
  counter-increment: list-1;
}
/deep/ ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
/deep/ ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-2 {
  counter-increment: list-2;
}
/deep/ ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
/deep/ ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-3 {
  counter-increment: list-3;
}
/deep/ ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
/deep/ ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-4 {
  counter-increment: list-4;
}
/deep/ ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
/deep/ ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-5 {
  counter-increment: list-5;
}
/deep/ ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
/deep/ ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-6 {
  counter-increment: list-6;
}
/deep/ ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
/deep/ ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
/deep/ ol li.ql-indent-7 {
  counter-increment: list-7;
}
/deep/ ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
/deep/ ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
/deep/ ol li.ql-indent-8 {
  counter-increment: list-8;
}
/deep/ ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
/deep/ ol li.ql-indent-8 {
  counter-reset: list-9;
}
/deep/ ol li.ql-indent-9 {
  counter-increment: list-9;
}
/deep/ ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
/deep/ .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
/deep/ li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
/deep/ .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
/deep/ li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
/deep/ .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
/deep/ li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
/deep/ .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
/deep/ li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
/deep/ .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
/deep/ li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
/deep/ .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
/deep/ li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
/deep/ .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
/deep/ li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
/deep/ .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
/deep/ li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
/deep/ .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
/deep/ li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
/deep/ .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
/deep/ li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
/deep/ .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
/deep/ li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
/deep/ .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
/deep/ li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
/deep/ .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
/deep/ li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
/deep/ .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
/deep/ li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
/deep/ .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
/deep/ li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
/deep/ .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
/deep/ li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
/deep/ .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
/deep/ li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
/deep/ .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
/deep/ li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
/deep/ .ql-video {
  display: block;
  max-width: 100%;
}
/deep/ .ql-video.ql-align-center {
  margin: 0 auto;
}
/deep/ .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
/deep/ .ql-bg-black {
  background-color: #000;
}
/deep/ .ql-bg-red {
  background-color: #e60000;
}
/deep/ .ql-bg-orange {
  background-color: #f90;
}
/deep/ .ql-bg-yellow {
  background-color: #ff0;
}
/deep/ .ql-bg-green {
  background-color: #008a00;
}
/deep/ .ql-bg-blue {
  background-color: #06c;
}
/deep/ .ql-bg-purple {
  background-color: #93f;
}
/deep/ .ql-color-white {
  color: #fff;
}
/deep/ .ql-color-red {
  color: #e60000;
}
/deep/ .ql-color-orange {
  color: #f90;
}
/deep/ .ql-color-yellow {
  color: #ff0;
}
/deep/ .ql-color-green {
  color: #008a00;
}
/deep/ .ql-color-blue {
  color: #06c;
}
/deep/ .ql-color-purple {
  color: #93f;
}
/deep/ .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
/deep/ .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
/deep/ .ql-size-small {
  font-size: 0.75em;
}
/deep/ .ql-size-large {
  font-size: 1.5em;
}
/deep/ .ql-size-huge {
  font-size: 2.5em;
}
/deep/ .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
/deep/ .ql-align-center {
  text-align: center;
}
/deep/ .ql-align-justify {
  text-align: justify;
}
/deep/ .ql-align-right {
  text-align: right;
}
/deep/.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.bg {
  background-color: #f3f4f6;
  margin-bottom: -30px;
}
.container {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.banner {
  height: 16px;
  width: 248px;
  text-align: left;
  color: #a5a5a5;
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 16px;
}
.middle {
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
}
.content-img {
  padding: 40px 40px 0 40px;
}
.content-img img {
  width: 1120px;
  height: 438px;
}
.content-text {
  padding: 48px 120px 64px 120px;
}
.content-text p {
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  color: #2d3748;
}
.title {
  margin-top: 14px;
  margin-bottom: 64px;
}
.title span {
  font-size: 16px;
  font-weight: 300;
  color: #2d3748;
}
/deep/.text {
  margin-bottom: 64px;
  margin-top: 30px;
  p {
    margin-top: 20px;
    text-indent: 2em;
  }
  img {
    max-width: 100%;
  }
}
</style>